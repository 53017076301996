<template>
  <login :urlLogin="urlLogin" :redirectPath="redirectPath" :img="img"></login>
</template>
<script>
export default {
  components: {
    login: () => import('@core/spore-components/login/login2.vue')
  },
  data () {
    return {
      urlLogin: 'login',
      redirectPath: '/menu',
      img: 'login_spore.png'
    }
  }
}
</script>
